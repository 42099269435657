<template>
    <div>
        <div class="create-case">
            <template v-if="sectionIndex === 0">
                <span class="head">
                    <h2>Select the type of Product</h2>
                    <p>Select the type of product that suits your requirements</p>
                </span>

                <div class="products__loader__backdrop" v-if="fetchingProducts">
                    <ne-loader :loading="true" />
                </div>

                <div class="cards" v-else>
                    <neo-case-card v-for="(type, index) in products" :key="index" :content="type" @click="nextSection(type)" />
                </div>
            </template>

            <div class="start" v-if="sectionIndex === 1">
                <div class="case-head">
                    <h1>{{ currentType.label }} Case Submission</h1>
                </div>

                <div class="case-board-body">
                    <p class="case-content">{{ currentType.content }}</p>
                    <span class="head">
                        <h2>Let's Onboard the Case</h2>
                        <p>Please fill in all relevant fields. Fields marked * are mandatory.</p>
                    </span>
                </div>

                <span class="actions">
                    <neo-button padding="1rem 3rem" margin="0 0.5rem" label="Back" bg="transparent" color="var(--text-fade-color)" hoverBg="var(--brand-accent-area-hover-on)" hoverText="var(--text-fade-color)" @click="prevSection()" />
                    <neo-button padding="1rem 3rem" margin="0 0.5rem" :label="'start'" bg="var(--brand-color)" color="white" hoverBg="var(--brand-hover-on)" hoverText="white" @click="nextSection()" />
                </span>
            </div>
        </div>

        <div class="form__container" v-if="sectionIndex === 2">
            <neo-progress-step @stepChanges="onStepChanges($event)" :count="productTemplate.product_fields.length" :currentStep="activeStageIndex + 1" />
            <div v-for="(stage, index) in productTemplate.product_fields" :key="index" class="stages">
                <div class="stage" v-if="index == activeStageIndex - 1">
                    <p>step {{ index + 1 }} of {{ productTemplate.product_fields.length }}</p>

                    <h3 class="stage__title">{{ stage[0][0].field_stage }}</h3>

                    <div v-for="(fieldSet, idx) in stage" :key="idx" class="field__set" style="width: 100%">
                        <div>
                            <p class="field__set__title">
                                {{ fieldSet[0].field_set }}
                            </p>

                            <p></p>
                            <form style="width: 100%">
                                <div v-for="(field, f_idx) in fieldSet" :key="f_idx" class="field">
                                    <neo-input v-if="field.field_type == 'text'" :changeCase="true" :placeholder="field.label" borderFull="1px solid var(--brand-accent-neutral)" bg="white" margin="0.5rem 0" v-model="caseData[field.field_id]" />

                                    <neo-input v-if="field.field_type == 'url'" :changeCase="false" :placeholder="field.label" borderFull="1px solid var(--brand-accent-neutral)" bg="white" margin="0.5rem 0" v-model="caseData[field.field_id]" />

                                    <div v-if="field.field_type == 'date'">
                                        <neo-datepicker v-if="field.field_type == 'date'" placeholder="Date of Birth" bg="white" borderFull="1px solid var(--brand-accent-neutral)" v-model="caseData[field.field_id]" />
                                    </div>

                                    <neo-radios v-if="field.field_type == 'radio'" :label="field.label" :options="field.options" v-model="caseData[field.field_id]" />

                                    <div v-if="field.field_type == 'select'">
                                        <span class="select__lablel" style="margin-bottom: 10px">{{ field.label }}</span>
                                        <v-select class="select" :options="field.options" v-model="caseData[field.field_id]" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <span class="actions">
                <neo-button padding="1rem 3rem" margin="0 0.5rem" label="Back" bg="transparent" color="var(--text-fade-color)" hoverBg="var(--brand-accent-area-hover-on)" hoverText="var(--text-fade-color)" @click="onBack()" />

                <neo-button padding="1rem 3rem" margin="0 0.5rem" :label="activeStageIndex === 0 ? 'Start' : activeStageIndex === productTemplate.product_fields.length ? 'Submit' : 'Next'" bg="var(--brand-color)" color="white" hoverBg="var(--brand-hover-on)" hoverText="white" @click="onNext()" />
            </span>
        </div>
    </div>
</template>
<script>
import Input from "@/components/input";
import Button from "@/components/button";
import ProgressStep from "@/components/progress-step";
import Datepicker from "@/components/datepicker";
import Radios from "@/components/radios";

import CaseCard from "@/containers/case-card";
import {caseApi} from "../../main.js";
import Loader from "@/components/loader";

export default {
    name: "CaseForm",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-progress-step": ProgressStep,
        "neo-datepicker": Datepicker,
        "neo-case-card": CaseCard,
        "neo-radios": Radios,
        "ne-loader": Loader,
    },
    mounted() {
        this.fetchProducts();
    },
    data() {
        return {
            productTemplate: null,
            activeStageIndex: 1,
            sectionIndex: 0,
            caseData: {},
            products: [],
            // productForm:null,
            currentType: "",
            fetchingProducts: true,
        };
    },
    methods: {
        async getProductForm() {
            this.$store.dispatch("getProductById", this.currentType.product_id);
        },
        async nextSection(type) {
            this.sectionIndex++;
            if (type) {
                this.currentType = type;
                await this.getProductForm();
            }
        },
        prevSection() {
            this.sectionIndex--;
        },
        async fetchProducts() {
            let response = await caseApi.get(`/api/v1/products`);
            this.fetchingProducts = false;
            this.products = response.data;
        },
        onBack() {
            if (this.activeStageIndex > 1) this.activeStageIndex--;
            else if (this.activeStageIndex == 1) this.sectionIndex = 0;
        },
        async onNext() {
            if (this.activeStageIndex < this.productTemplate.product_fields.length) {
                this.activeStageIndex++;
            }
            if (this.activeStageIndex == this.productTemplate.product_fields.length) {
                //save the case data

                let caseData = [];
                for (const [key, value] of Object.entries(this.caseData)) {
                    caseData.push({
                        product_field_id: key,
                        field_value: value,
                    });
                }
                let payload = {
                    product_id: this.currentType.product_id,
                    case_fields: caseData,
                };

                try {
                    let response = await caseApi.post(`/api/v1/cases`, payload);
                    if (response.status == 201) this.$toasted.show("case saved");
                } catch (err) {
                    if (err.response.status == 400) this.$toasted.show("Error: Could not create case!");
                }
            }
        },
        onStepChanges(step) {
            this.activeStageIndex = step;
        },
    },
};
</script>
<style lang="scss">
.stage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stage__title {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.field__set__title {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: left;
}
.actions {
    display: flex;
    align-items: center;
    margin: 3rem;
    justify-content: center;
}
.steps {
    margin-top: 3rem;
    p {
        color: var(--text-fade-color);
    }
}
.select {
    background-color: white;
}
.vs__dropdown-toggle {
    border: none;
    border-radius: 10px;
}

.vs__clear {
    display: none;
}
.form__container {
    text-align: center;
    margin: auto;
    width: 60%;
    margin-top: 200px;
}
.progress-bar {
    margin: auto;
    width: 100% !important;
}
.radios {
    justify-content: space-evenly !important;
}

.select__lablel {
    flex-direction: column;
    display: flex;
    align-items: self-start;
}
#vs1__combobox {
    height: 45px;
}

.products__loader__backdrop {
    width: 100%;
    height: 500px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.radio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
</style>
<style src="./style.scss" scoped lang="scss"></style>
