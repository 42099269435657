export default {
    name: "neo-case-card",
    components: {},
    props: ["content"],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
