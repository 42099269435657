export default {
    name: "neo-progress-step",
    components: {},
    props: ["count", "currentStep"],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
